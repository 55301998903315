import clsx from 'clsx';
import React, { FC } from 'react';
import { IWithClassName } from '~/models/dev';
import styles from './value-with-caption.module.scss';

interface IValueWithCaptionProps extends IWithClassName {
    caption?: string;
    captionClassName?: string;
}

const ValueWithCaption: FC<IValueWithCaptionProps> = ({ caption, children, className, captionClassName }) => (
    <div className={className}>
        <span className={clsx(styles.caption, captionClassName)}>{caption}</span>
        {children}
    </div>
);

export default ValueWithCaption;
