/* eslint-disable import/no-dynamic-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { SVGAttributes, FC, memo } from 'react';
import clsx from 'clsx';
import styles from './svg.module.scss';

export interface ISvgIconProps extends SVGAttributes<SVGElement> {
    name: string;
    thick?: boolean;
}

/**
 * @description
 * Renders an SVG from /public/svg. In the example shown below, it assumes the file /public/svg/barcode.svg exists.
 *
 * @example
 * <Svg name="shipping" thick />
 *
 * @description
 * All paths should have vector-effect="non-scaling-stroke" and stroke-width="1". We can then use the attribute thick to set the stroke width to 1.5px
 * Icons should ALWAYS be black like stroke="#000"
 * Icons should NOT have width and height on the <svg> tag.
 * Icons should ALWAYS have viewBox="0 0 24 24" so all icons have same viewbox
 */
export const Svg: FC<ISvgIconProps> = memo((props) => {
    const { name, thick = false, className, ...svgProps } = props;

    try {
        const { ReactComponent } = require(`../../../public/svg/${name}.svg`);

        return (
            <ReactComponent
                className={clsx(className, {
                    [styles.thick]: thick,
                })}
                {...svgProps}
            />
        );
    } catch (e) {
        // TODO: Log error.
        return <></>;
    }
});
